const resource = {
    en: {
        paragraph_1: 'The ‘Matrix for Designing History Courses’ is a digital tool for planning and designing learning units for university courses of teacher education as well as for school lessons in the subjects of ‘history’, ‘social studies’, ‘citizenship education’, ‘civics’ and/or ‘cultural education’.',
        paragraph_2: 'Based on the theory of process-oriented history didactics (Alois Ecker), this matrix supports the planning and design of learning settings that can lead learners to historical and political thinking. To strengthen subject didactics, concepts of historical writing (1st order concepts) are linked with concepts of historical narration and historical thinking (2nd order concepts). The organisation of teaching and learning is structured in such a way that processes of historical learning/ learning on society and politics can be mapped in the circularity of the analysis of the addressees (learning group), objectives of the learning unit, selection of topic(s), communication structure(s), transfer, back-coupling/feedback and reflection.',
        info: {
            list_1: {
                heading: 'The planning matrix includes the following areas:',
                items: [
                    'general information on the planned learning unit',
                    'objectives (concepts, competences, learning objectives)',
                    'learning environment and target group (addressees)',
                    'expectation horizon and teachers\' pre-concepts (teacher\'s beliefs)',
                    'matrix for sequencing the learning unit'
                ]
            },
            list_2: {
                heading: 'The digital tool includes several features which promote networked learning, online/distance learning, cooperative and interactive work:',
                items: [
                    'linking of the central elements of the planning matrix with the corresponding learning modules as well as with theoretical approaches (theory of history, systems theory, communication theory).',
                    'possibility of collaborative work: joint processing of the planning matrix by several people (learning groups)',
                    'review and comment function.'
                ]
            },
            paragraph: 'The planning matrix was developed by Prof. Dr. Alois Ecker with the collaboration of Dr. Bettina Paireder. It is now used in many teacher training institutions in Austria as well as in European, intercultural and transnational education projects. - The use of the digital planning matrix as a tool for research in history didactics is being prepared.'
        }
    },
    de: {
        paragraph_1: 'Die Planungsmatrix ist ein digitales Tool zur Planung und Gestaltung von Lerneinheiten für die universitäre Ausbildung von Lehrerinnen und Lehrern der Unterrichtsfächer Geschichte, Sozialkunde, Politische Bildung sowie für den schulischen Unterricht dieser Fächer.',
        paragraph_2: 'Basierend auf der Theorie der prozessorientierten Geschichtsdidaktik (Alois Ecker) unterstützt diese Matrix die Planung von Lernsettings, die die Lernenden zu historischem und politischem Denken führen können. Zur Stärkung des fachdidaktischen Denkens werden dabei Konzepte des historischen Schreibens (1st order concepts) mit Konzepten des historischen Erzählens und Denkens (2nd order concepts) vernetzt: Die Lernorganisation wird dabei strukturell so aufbereitet, dass Prozesse des historischen Lernens in der Zirkularität von Adressat*innenanalyse, Zielsetzungen, Themenwahl, Kommunikationsstruktur, Transfer, Rückkoppelung und Reflexion abgebildet werden können.',
        info: {
            list_1: {
                heading: 'Die Planungsmatrix umfasst die folgenden Bereiche:',
                items: [
                    'allgemeine Informationen zur geplanten Lerneinheit',
                    'Zielsetzungen (Konzepte, Kompetenzen, Lernziele)',
                    'Lernumgebung und Zielgruppe (Adressat*innen)',
                    'Erwartungshorizont und Vorkonzepte der Lehrenden (Teacher’s Beliefs)',
                    'Matrix zur Sequenzierung der Unterrichtseinheit'
                ]
            },
            list_2: {
                heading: 'Das digitale Tool umschließt eine Reihe an Features, die vernetztes Lernen, online-/distance-learning, kooperatives und interaktives Arbeiten fördern:',
                items: [
                    'Verknüpfung der zentralen Elemente der Planungsmatrix mit den entsprechenden Lernmodulen sowie mit theoretischen Ansätzen (Geschichtstheorie, Systemtheorie, Kommunikationstheorie)',
                    'Möglichkeit des kollaborativen Arbeitens: gemeinsame Bearbeitung der Planungsmatrix von mehreren Personen (Lerngruppen)',
                    'Review- und Kommentarfunktion'
                ]
            },
            paragraph: 'Die Planungsmatrix wurde von Univ.-Prof. Dr. Alois Ecker unter Mitarbeit von Dr. Bettina Paireder entwickelt und wird mittlerweile in vielen Lehrer*innenbildungseinrichtungen Österreichs sowie in Europäischen und interkulturellen, transnationalen Bildungsprojekten eingesetzt. - Die Verwendung der digitalen Planungsmatrix als Tool für die geschichtsdidaktische Forschung wird vorbeitet.'
        }
    }
}

export default resource;