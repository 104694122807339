<template>
  <div class="home">
    <div class="hero flex">
      <div class="heading">
        <h1>Matrix for<br>designing<br>courses in<br>detailed sequences</h1>
      </div>
      <div class="introduction text">
        <p v-html="resource[lang].paragraph_1" />
        <p v-html="resource[lang].paragraph_2" />

        <Button 
          class="call-to-action" 
          label="Planungsmatrix" 
          color="accent" 
          stretch 
          @click="gotoPlanungsmatrix"
        />
      </div>
    </div>

    <section class="info text">
      <div class="grid">
        <div>
          <p v-html="resource[lang].info.list_1.heading" />
          <ul>
            <li v-for="(item, index) in resource[lang].info.list_1.items" :key="index" v-html="item" />
          </ul>
        </div>
        <div>
          <p v-html="resource[lang].info.list_2.heading" />
          <ul>
            <li v-for="(item, index) in resource[lang].info.list_2.items" :key="index" v-html="item" />
          </ul>
        </div>
      </div>
      <p v-html="resource[lang].info.paragraph" />
    </section>

    <div class="learning-module-overview">
      <HorizontalLearningModuleCard 
        v-for="(module, index) in learning_modules"
        :key="module.id"
        :title="module.name[lang]"
        :description="module.description[lang]"
        :link="`/learning-module/${module.id}`"
        :image_src="images[index]"
        image_alt_text=""
      />
    </div>
  </div>
</template>

<script>
import resource from '@/assets/resources/home-resource'

import Button from '@/components/basic/Button'
import HorizontalLearningModuleCard from '@/components/HorizontalLearningModuleCard'

import { promiseGetRequest } from '@/assets/js/axios-utilities'

export default {
  title: 'Planungsmatrix - Matrix for Designing Courses inDetailed Sequences',
  components: {
    Button,
    HorizontalLearningModuleCard
  },
  data() {
    const lang = localStorage.getItem('lang') || 'en';

    return {
      lang: lang,

      learning_modules: [],
      images: [
        'https://images.unsplash.com/photo-1457369804613-52c61a468e7d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
        'https://images.unsplash.com/photo-1606761568499-6d2451b23c66?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80',
        'https://images.unsplash.com/photo-1503551723145-6c040742065b-v2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
        'https://images.unsplash.com/photo-1586769852836-bc069f19e1b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
        'https://images.unsplash.com/photo-1495446815901-a7297e633e8d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
      ],

      resource
    }
  },
  async created() {
    const response = await this.learningModulesRequest();
    this.learning_modules = response.data;
  },
  mounted() {
      window.scrollTo(0, 0);
  },
  methods: {
    // Requests
    learningModulesRequest() {
      return promiseGetRequest('learning-modules');
    },

    // Actions
    gotoPlanungsmatrix() {
      this.$router.push('/planungsmatrix');
    }
  }
}
</script>

<style lang="scss" scoped>

h1 {
  color: var(--accent);

  font-family: var(--standard-font);
  font-size: clamp(2rem, 1rem + 3.4vw , 5rem);
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
}

.call-to-action {
  margin-top: 4rem;
}

.flex {
  justify-content: space-between;
}

.heading {
  flex-basis: 60%;
}

.hero p + p {
  margin-top: 1em;
}

.info {
  margin-block-start: 10rem;
  margin-block-end: 2rem;

  max-width: 80rem;

  margin-inline-start: auto;
  margin-inline-end: auto;

  ul {
    margin-block-start: 1rem;
    margin-inline-start: 1.5rem;
  }

  li {
    margin-block-end: 0.5rem;
  }

  .grid {
    gap: 2rem;

    margin-block-end: 4rem;
    padding: 2rem;

    background-color: var(--light_grey);
    
    
  }

  & > p {
    max-width: 70ch;

    margin-inline-start: auto;
    margin-inline-end: auto;
  }
}

.introduction {
  flex-basis: 34%;
}

.learning-module-overview {
  margin-top: 14rem;

  & > * + * {
    margin-block-start: 2.5rem;
  }
}

@media (max-width: 50rem) {
  h1 {
    margin-bottom: 2rem;
  }

  .flex,
  .grid {
    display: block;
  }

  .grid {
    div + div {
      margin-top: 3rem;
    }
  }

  .home {
    padding: 0.5rem;
  }
}

</style>
